import { Modal } from '/features/buildingBlocks/Modal'
import { Image } from '/features/buildingBlocks/Image'
import { VideoPlayer, useVideoTracking } from '/features/buildingBlocks/VideoPlayer'
import { InfoBlock } from '/features/home/pageOnly/InfoBlock'
import { useTranslate } from '/machinery/I18n'
import { ButtonPlay } from '/features/buildingBlocks/ButtonPlay'

import styles from './HomeHeroVideos.css'

export function HomeHeroVideos({ heroImages }) {
  const { __ } = useTranslate()
  const [
    ondernemingsrechtImage,
    personenEnFamilierechtImage,
    registergoederenrechtImage
  ] = heroImages

  return (
    <div className={styles.component}>
      <div className={styles.videos}>
        <div className={cx(styles.video, styles.firstVideo)}>
          <div className={cx(styles.aspectRatio, styles.aspectRatioFirst)}>
            <Video name="ondernemingsrecht" url='https://vimeo.com/654591525' text={__`ondernemingsrecht`} image={ondernemingsrechtImage} />
          </div>
        </div>
        <div className={cx(styles.video, styles.secondVideo)}>
          <div className={cx(styles.aspectRatio, styles.aspectRatioSecond)}>
            <Video name="personenEnFamilierecht" url='https://vimeo.com/654591815' text={__`personen-en-familierecht`} image={personenEnFamilierechtImage} />
          </div>
        </div>
        <div className={cx(styles.video, styles.thirdVideo)}>
          <div className={cx(styles.aspectRatio, styles.aspectRatioThird)}>
            <Video name="registergoederenrecht" url='https://vimeo.com/654592091' text={__`registergoederenrecht`} image={registergoederenrechtImage} />
          </div>
        </div>
      </div>
      <InfoBlock layoutClassName={styles.infoBlock} text={__`elke-notaris-heeft-zijn-eigen-specialisme`} />
    </div>
  )
}

function Video({ name, image, text, url }) {
  const [fullScreen, setFullScreen] = React.useState(false)
  const { eventHandlers } = useVideoTracking({ name, type: 'normal' })

  return (
    <div className={styles.componentVideo}>
      <div className={cx(styles.button, styles.relativeToParent)}>
        <ButtonPlay onClick={() => setFullScreen(true)} relativeToParent {... { text }} />
      </div>
      <Image layoutClassName={styles.image} {...{ image }} />
      <Modal onClose={() => setFullScreen(false)} visible={fullScreen}>
        <VideoPlayer playing={fullScreen} {...{ url, eventHandlers }} />
      </Modal>
    </div>
  )
}
